import {Upload, Icon, notification} from 'antd';
import React, {useState} from 'react'
import {Spin} from 'antd';
import {UploadProps} from 'antd/lib/upload/Upload';
import {useHistory} from "react-router-dom";
import {CrawlSteps} from "./Steps";
import {RouteComponentProps} from "react-router";

const {Dragger} = Upload;

export type ScrapeRunItemDetails = {
    createdAt: Date,
    updatedAt: Date,
    status: string,
    websiteUrl: string,
    companyId: string,
    id: string,
    companyName: string,
    finishedAt: Date,
    companyMatch: string
    keywordMatchResult: string,

    cityFound: boolean;
    addressFound: boolean;
    zipCodeFound: boolean;
    companyNameFound: boolean;

    websiteAddressImputed: boolean;

    timedOut: boolean;
    thumbScore: number;
    reason: null | string
}

export type ScrapeRunDetails = {
    id: string,
    name: string,
    inputFileLocation: string,
    numberOfWebsitesProcessed: number,
    totalNumberOfWebsites: number,
    outputFileLocation: string,
    createdAt: string,
    withKeywords: boolean
    status: string
    items: Array<ScrapeRunItemDetails>
}


type TParams = { runType: string };


const UploadJobSpecificationScreen = (props: RouteComponentProps<TParams>) => {
    const {match} = props;

    const [isLoading, setIsLoading] = useState(false);
    const [, setIsUploaded] = useState(false);

    let history = useHistory()

    if (!['pre', 'post'].includes(match.params.runType)) {
        return (
            <p>Invalid run type (pre/post) internet-review.</p>
        )
    }

    const settings: UploadProps = {
        name: 'jobSpecification',
        action: `/api/v1/scrape/${match.params.runType}`,
        showUploadList: false,
        onChange(info: any) {
            const {status} = info.file;

            if (status === 'uploading') {
                setIsLoading(true);
            }
            if (status === 'done') {
                const scrapeRunDetails: ScrapeRunDetails = info.file.response;
                setIsLoading(false);

                notification['success']({
                    message: `${info.file.name} uploaded successfully.`,
                    description: `Please upload the keywords`
                })

                const path = `/scrape/${scrapeRunDetails.id}/keywords`;
                history.push(path)

                setIsUploaded(true);

            } else if (status === 'error') {
                setIsLoading(false);
                if (info.file.response && info.file.response.message == null) {
                    notification['error']({
                        message: `${info.file.name} upload failed.`,
                        description: `${info.file.response}`,
                        duration: 0,
                        style: {
                            width: 400,
                        }
                    })
                    return
                }

                const messages = info.file.response.message.split(',')
                for (const message of messages) {
                    notification['error']({
                        message: `${info.file.name} upload failed.`,
                        description: `${message}`,
                        duration: 0,
                        style: {
                            width: 400,
                        }
                    })
                }
            }
        }
    };

    const antIcon = <Icon type="loading" style={{fontSize: 24, color: '#808080'}} spin/>;

    return (

        <Spin size="large" delay={200} spinning={isLoading} indicator={antIcon}>

            <CrawlSteps currentStep={0}/>
            <br/>
            <Dragger {...settings}>
                <p className="ant-upload-drag-icon">
                    <Icon style={{color: '#808080'}} type="inbox"/>
                </p>
                <p className="ant-upload-text">Click or drag BAT-file to this area to upload</p>
                <p className="ant-upload-hint">
                    Please make sure the Excel file is correctly formatted.
                </p>

            </Dragger>
        </Spin>

    )
}

export default UploadJobSpecificationScreen