import React  from 'react';
import {Button, Result} from 'antd';

const ScrapeCancelled = () => {
    return (
        <Result
            status="error"
            title="Job has been cancelled"
            subTitle="Please check and modify the information before resubmitting."
            extra={
                <Button href='/' key="homepage">
                    Back to overview
                </Button>
            }
        />
    )

}
export default ScrapeCancelled