import React from 'react';
import {Result, Button, Input, Col, Row, Divider} from 'antd';
import {Icon, Progress} from 'antd';
import {CrawlSteps} from "./Steps";
import {ScrapeRunItemDetails} from "./UploadJobSpecificationScreen";
import ScrapeItemDetailsTable from "./ScrapeItemDetailsTable";
import {ScrapeRunDetails} from "./UploadJobSpecificationScreen";
import ScrapeRunStatistics from "./ScrapeRunStatistics";

const {Search} = Input;
type TScrapeRunningProps = {
    numberOfWebsitesProcessed: number
    totalNumberOfWebsites: number,
    scrapeRunItems: Array<ScrapeRunItemDetails>
    onCancelled: (event: any) => void
    onForceFinish: (event: any) => void
    scrapeRun: ScrapeRunDetails
    isLoading: boolean
    isSearching: boolean
    handleSearch(term: string): void
    postThumbScore(scrapeRunId: string, scrapeRunItemId: string, score: number): void
}


const ScrapeRunning = (props: TScrapeRunningProps) => {
    const percent = Math.round((props.numberOfWebsitesProcessed / props.totalNumberOfWebsites) * 100);
    const randomSentences = [
        "Visiting websites ... ",
        "Taking screenshots ...",
        "Following all the links ..."
    ];

    let subTitle = `Scraped ${props.numberOfWebsitesProcessed}/${props.totalNumberOfWebsites} websites.`;
    if (props.numberOfWebsitesProcessed === 0) {
        // ETA cannot be calculated when no website has been processed yet.
        subTitle = `The run is still starting ...`
    }

    return (
        <div>
            <CrawlSteps scrapeRun={props.scrapeRun} currentStep={2}/>
            <Row>
                <ScrapeRunStatistics scrapeRun={props.scrapeRun} scrapeRunItems={props.scrapeRunItems}/>
            </Row>
            <Result
                icon={<Icon type="smile" style={{color: '#ffe600'}}/>}
                title={randomSentences[Math.floor(Math.random() * randomSentences.length)]}
                subTitle={subTitle}
                extra={[<Progress
                    strokeColor={{
                        from: '#ffe600',
                        to: '#ffcc00',
                    }}
                    percent={percent}
                    status="active"
                    key="pass"
                />,

                    <Button onClick={props.onCancelled} key="cancel">
                        Cancel run
                    </Button>,
                    <Button onClick={props.onForceFinish} key="force-finish">
                        Force finish
                    </Button>
                ]}
            />


            <Divider/>

            <Row justify="end">
                <Col span={18}></Col>
                <Col span={6}>
                    <Search enterButton style={{marginBottom: "20px"}} placeholder="input search text"
                            onSearch={value => props.handleSearch(value)}/>
                </Col>
            </Row>

            <ScrapeItemDetailsTable postThumbScore={props.postThumbScore} scrapeRunItems={props.scrapeRunItems}
                                    isLoading={props.isLoading} scrapeRun={props.scrapeRun}/>
        </div>
    )

};

export default ScrapeRunning