// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const getRelease = () => {
    const commit = process.env.REACT_APP_CI_COMMIT_SHORT_SHA;
    if (commit === undefined) {
        return 'dev';
    }
    return commit
};


Sentry.init({
    dsn: "https://7afc31446f9f4b61ac40e7bdc5dbc8e7@sentry.io/5049051",
    release: getRelease()
});

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
