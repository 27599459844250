import React from 'react';
import {ScrapeRunDetails, ScrapeRunItemDetails} from "./UploadJobSpecificationScreen";

import {Statistic, Card, Row, Col} from 'antd';


type TScrapeStatisticsProps = {
    scrapeRunItems: Array<ScrapeRunItemDetails>
    scrapeRun: ScrapeRunDetails;
}
const numberOfWebsites = (scrapeRunItems: Array<ScrapeRunItemDetails>) => {
    return scrapeRunItems.length
}

const numberOfWebsitesSkipped = (scrapeRunItems: Array<ScrapeRunItemDetails>) => {
    return scrapeRunItems.filter((item) => item.status === "SKIPPED").length
}

const numberOfWebsitesFailed = (scrapeRunItems: Array<ScrapeRunItemDetails>) => {
    return scrapeRunItems.filter((item) => item.status === "FAILED").length
}


const ScrapeRunStatistics = (props: TScrapeStatisticsProps) => {
    return (
        <Row gutter={24}>
            <Col span={4}>
                <Card>
                    <Statistic
                        title="Websites"
                        value={numberOfWebsites(props.scrapeRunItems)}
                        valueStyle={{color: '#000000'}}
                    />
                </Card>
            </Col>
            <Col span={5}>
                <Card>
                    <Statistic
                        title="Skipped"
                        value={numberOfWebsitesSkipped(props.scrapeRunItems)}
                        valueStyle={{color: '#000000'}}
                    />
                </Card>
            </Col>
            <Col span={5}>
                <Card>
                    <Statistic
                        title="Failed"
                        value={numberOfWebsitesFailed(props.scrapeRunItems)}
                        valueStyle={{color: '#cf1322'}}
                    />
                </Card>
            </Col>
        </Row>
    );
}
export default ScrapeRunStatistics

