import React from 'react';

import {
    Layout,
    Col,
    Row
} from 'antd'
import './App.less'
import logo from './media/logo.svg'
import UploadJobSpecificationScreen from "./components/UploadJobSpecificationScreen";
import FeedbackOverviewScreen from "./components/FeedbackOverviewScreen"
import UploadKeywordScreen from "./components/UploadKeywordScreen";
import LandingScreen from "./components/LandingScreen";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import ScrapeDetailsScreen from './containers/ScrapeScreen';
import NotSupportedScreen from "./components/NotSupported";

const {detect} = require('detect-browser');

const {Header, Content} = Layout;

export const CONTENT_CONTAINER_ID = 'project-dashboard-content';


const App: React.FC = () => {

    const browser = detect();
    switch (browser && browser.name) {
        case 'chrome':
        case 'firefox':
            return (
                <Router>
                    <Layout style={{height: "100vh"}}>
                        <Header className={"irb-layout-header"}>
                            <Row type="flex" justify="space-between" align="middle">
                                <Col span={2}>
                                    <div className={"header-logo-container"}>
                                        <Link to='/'>
                                            <img src={logo} alt={""} className={"header-logo"}/>
                                        </Link>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <h1 className={"header-app-title"}>Scraper</h1>
                                </Col>
                                <Col span={12} style={{textAlign: 'right', right: '2em'}}>

                                </Col>
                            </Row>
                        </Header>
                        <Content className={"irb-layout-content"} id={CONTENT_CONTAINER_ID}>
                            <Route path="/" exact component={LandingScreen}/>
                            <Route path="/feedback" component={FeedbackOverviewScreen}/>
                            <Route path="/start/:runType" component={UploadJobSpecificationScreen}/>
                            <Route exact path="/scrape/:id" component={ScrapeDetailsScreen}/>
                            <Route exact path="/scrape/:id/keywords" component={UploadKeywordScreen}/>
                        </Content>
                    </Layout>
                </Router>
            );

        default:
            return (<NotSupportedScreen />)
    }


};

export default App;
