import React from 'react';
import {Badge, Button, Icon} from 'antd';

const ButtonGroup = Button.Group;

interface IThumbsComponentProps {
    thumbScore: number
    scrapeRunId: string
    scrapeRunItemId: string

    postThumbScore(scrapeRunId: string, scrapeRunItemId: string, score: number): void
}

const ThumbsComponent = (props: IThumbsComponentProps) => {
    const updateScore = (type: string) => {
        if (type === "dislike") {
            return props.postThumbScore(props.scrapeRunId, props.scrapeRunItemId, -1)
        }

        return props.postThumbScore(props.scrapeRunId, props.scrapeRunItemId, 1)
    }

    const getStatusColor = (thumbScore: number) => {
        if (thumbScore > 0) {
            return 'green'
        }

        return 'red'
    }

    return (
        <div>
            <Badge style={{backgroundColor: getStatusColor(props.thumbScore)}} count={props.thumbScore}>
                <ButtonGroup>
                    <Button onClick={() => updateScore("dislike")}>
                        <Icon type="dislike"
                        />
                    </Button>
                    <Button onClick={() => updateScore("like")}>
                        <Icon type="like"
                        />
                    </Button>

                </ButtonGroup>
            </Badge>
        </div>
    )
};

export default ThumbsComponent;