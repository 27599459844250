import React  from 'react';
import {Result} from 'antd';

const ErrorScreen = () => {
    return (
        <Result
            status="500"
            title="Error fetching details from the API."
            subTitle="Please contact the system administrator."
        />
    )

}
export default ErrorScreen