import React from 'react';
import {Button, Input, Row, Col, Divider} from 'antd';
import {Result} from 'antd';
import {CrawlSteps} from "./Steps";
import ScrapeItemDetailsTable from "./ScrapeItemDetailsTable";
import {ScrapeRunItemDetails} from "./UploadJobSpecificationScreen";
import {ScrapeRunDetails} from "./UploadJobSpecificationScreen";

import ScrapeRunStatistics from "./ScrapeRunStatistics"

const {Search} = Input;
type TScrapeSuccessProps = {
    outputFileLocation: string,
    scrapeRunItems: Array<ScrapeRunItemDetails>
    scrapeRun: ScrapeRunDetails;
    isLoading: boolean;
    isSearching: boolean
    handleSearch(term: string): void
    postThumbScore(scrapeRunId: string, scrapeRunItemId: string, score: number): void
}

const ScrapeSuccess = (props: TScrapeSuccessProps) => {
    const url = `/excel/${props.scrapeRun.id}.xlsx`;
    return (
        <div>
            <CrawlSteps scrapeRun={props.scrapeRun} currentStep={3}/>
            <Row>
                <ScrapeRunStatistics scrapeRun={props.scrapeRun} scrapeRunItems={props.scrapeRunItems}/>
            </Row>
            <Result
                status="success"
                title="Scrape run finished!"
                subTitle="Click below to download the packaged results."
                extra={[
                    <Button key="download-all" href={props.outputFileLocation} type="primary" icon="download">
                        Download all
                    </Button>,
                    <Button key="download-excel" href={url} type="primary" icon="file-excel">
                        Download excel
                    </Button>,
                    <Button href="/start/pre" type="default" key="pre" icon="login">
                        Another run before internet-review
                    </Button>,
                    <Button href="/start/post" type="default" key="post" icon="logout">
                        Another run after internet-review
                    </Button>
                ]}
            />
            <Divider />

            <Row justify="end">
                <Col span={18}></Col>
                <Col span={6}>
                    <Search loading={props.isSearching} enterButton
                            style={{marginBottom: "20px"}} placeholder="input search text"
                            onSearch={value => props.handleSearch(value)}/>
                </Col>
            </Row>

            <ScrapeItemDetailsTable postThumbScore={props.postThumbScore} scrapeRunItems={props.scrapeRunItems}
                                    isLoading={props.isLoading} scrapeRun={props.scrapeRun}/>
        </div>

    )

};

export default ScrapeSuccess