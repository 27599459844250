import React from 'react';
import {Result} from 'antd';

const NotSupportedScreen = () => {
    return (
        <Result
            status="403"
            title="Current browser not supported."
            subTitle="Please use Google Chrome or Mozilla Firefox to use this tool."
        />
    )

};
export default NotSupportedScreen