import React, {useState, useEffect} from 'react';
import {notification, Skeleton, Modal, message} from 'antd';
import {RouteComponentProps} from 'react-router';
import {ScrapeApi} from '../services/ScrapeApi';

import useRecursiveTimeout from '../hooks';
import ScrapeSuccess from '../components/ScrapeSuccess';
import ScrapeZipping from '../components/ScrapeZipping';
import ScrapeRunning from '../components/ScrapeRunning';
import ScrapeQueued from '../components/ScrapeQueued';
import ScrapeCancelled from "../components/ScrapeCancelled";
import {ScrapeRunItemDetails} from "../components/UploadJobSpecificationScreen";

type TParams = { id: string };

function shouldPoll(status: string) {
    if (status !== "Finished" && status !== "Cancelled") {
        return true
    }
    return false
}

const ScrapeDetailsScreen = (props: RouteComponentProps<TParams>) => {
    const {match} = props;
    const {confirm} = Modal;

    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [data, setData] = useState({
        id: '',
        name: '',
        inputFileLocation: '',
        numberOfWebsitesProcessed: 0,
        totalNumberOfWebsites: 0,
        outputFileLocation: '',
        createdAt: '',
        status: 'string',
        items: new Array<ScrapeRunItemDetails>(),
        withKeywords: false
    });

    const [filteredItems, setFilteredItems] = useState(new Array<ScrapeRunItemDetails>());
    const scrapeRunId = match.params.id;

    useEffect(() => {
        const API = new ScrapeApi();
        API.getScrapeRunDetails(scrapeRunId).then(async result => {
            setIsLoading(false);
            setData(result);
            setFilteredItems(result.items)

        }).catch(err => {
            console.log(err)
        })
    }, [scrapeRunId]);

    useRecursiveTimeout(async () => {
        if (!shouldPoll(data.status)) {
            return
        }

        try {
            const API = new ScrapeApi();
            const newData = await API.getScrapeRunDetails(match.params.id);

            if (data.status !== "Finished" && newData.status === "Finished") {
                notification['success']({
                    message: `Scraping completed!`,
                    description: `You can now download the results`
                })
            }

            if (searchTerm === '' || newData.status === 'Finished') {
                setFilteredItems(newData.items)
            } else {
                handleSearch(searchTerm)
            }
            setData(newData);
        } catch (e) {
            // Do not update view when there is an issue with the API
            console.log(e)
        }
    }, 10000);


    const handleCancelButton = async (event: string) => {
        const API = new ScrapeApi();

        confirm({
            title: 'Are you sure you want to cancel this run?',
            content: 'This action cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const newData = await API.cancelRun(data.id);
                setData(newData)
            },
        });
    };

    const handleForceFinishButton = async (event: string) => {
        const API = new ScrapeApi();

        confirm({
            title: 'Are you sure you want force finish this run?',
            content: 'The remaining pages will not be scraped',
            okText: 'Yes',
            okType: 'link',
            cancelText: 'No',
            async onOk() {
                const newData = await API.forceFinishRun(data.id);
                setData(newData)
            },
        });
    };

    const postThumbScore = async (scrapeRunId: string, scrapeRunItemId: string, score: number) => {
        const api = new ScrapeApi();
        try {

            const scrapeRun = await api.postThumbs(scrapeRunId, scrapeRunItemId, score);

            if (searchTerm === '' || scrapeRun.status === 'Finished') {
                setFilteredItems(scrapeRun.items)
            } else {
                handleSearch(searchTerm)
            }
            setData(scrapeRun);

            if (score > 0) {
                message.info('Thumbs up saved!');
            } else {
                message.warning('Thumbs down saved!');
            }
            return false;

        } catch (e) {
            message.error(`${e}`);
        }
    };

    const handleSearch = (term: string) => {
        setIsSearching(true);

        setFilteredItems(data.items.filter((details) => {
            term = term.toLowerCase();
            if (details.companyId && details.companyId.toLowerCase().includes(term)) {
                return true;
            }

            if (details.websiteUrl && details.websiteUrl.toLowerCase().includes(term)) {
                return true;
            }

            if (details.companyName && details.companyName.toLowerCase().includes(term)) {
                return true;
            }
            return false;
        }));

        setIsSearching(false);
        setSearchTerm(term);
    };


    switch (data.status) {
        case 'QUEUED':
            return <ScrapeQueued/>;

        case 'RUNNING':
            return <ScrapeRunning
                numberOfWebsitesProcessed={data.numberOfWebsitesProcessed}
                totalNumberOfWebsites={data.totalNumberOfWebsites}
                scrapeRunItems={filteredItems}
                handleSearch={handleSearch}
                scrapeRun={data}
                isSearching={isSearching}
                isLoading={isLoading}
                onCancelled={handleCancelButton}
                onForceFinish={handleForceFinishButton}
                postThumbScore={postThumbScore}

            />;
        case 'FORCE_FINISH':
        case 'ZIPPING':
            return <ScrapeZipping/>;
        case 'FINISHED':
            return <ScrapeSuccess outputFileLocation={data.outputFileLocation}
                                  scrapeRunItems={filteredItems}
                                  scrapeRun={data}
                                  isSearching={isSearching}
                                  isLoading={isLoading}
                                  handleSearch={handleSearch}
                                  postThumbScore={postThumbScore}
            />;
        case 'CANCELLED':
            return <ScrapeCancelled/>;
        default:
            return <Skeleton active/>
    }
};

export default ScrapeDetailsScreen