import {Button, Icon, notification, Table, Tooltip} from "antd";
import React, {useState} from "react";
import {ScrapeRunDetails, ScrapeRunItemDetails} from "./UploadJobSpecificationScreen";
import {ScrapeApi} from "../services/ScrapeApi";
import WrappedFeedbackForm from "./FeedbackForm";
import ThumbsComponent from "./ThumbsComponent";

type TOverviewTableProps = {
    scrapeRunItems: Array<ScrapeRunItemDetails>,
    scrapeRun: ScrapeRunDetails
    isLoading: boolean;
    postThumbScore(scrapeRunId: string, scrapeRunItemId: string, score: number): void

}

const getCompanyResultFromRecord = (record: ScrapeRunItemDetails) => {
    if (record.companyNameFound && (record.zipCodeFound || record.addressFound || record.cityFound)) {
        return 'FULL_MATCH'
    }
    if (record.companyNameFound || record.zipCodeFound || record.addressFound || record.cityFound) {
        return 'PARTIAL_MATCH'
    }

    return 'NO_MATCH'
}
const ScrapeItemDetailsTable = (props: TOverviewTableProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedScrapeRunItemId, setSelectedScrapeRunItemId] = useState('');

    const columns = [
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            key: 'companyId',
            sorter: (a: ScrapeRunItemDetails, b: ScrapeRunItemDetails) => {
                return a.companyId.localeCompare(b.companyId)
            }
        },
        {
            title: 'Company name',
            dataIndex: 'companyName',
            key: 'companyName',
            // width: 400,
            sorter: (a: ScrapeRunItemDetails, b: ScrapeRunItemDetails) => {
                return a.companyName.localeCompare(b.companyName)
            }
        },

        {
            title: 'Website',
            dataIndex: 'websiteUrl',
            key: 'websiteUrl',
            render: (text: string, item: ScrapeRunItemDetails) => {
                if (item.websiteAddressImputed) {
                    return (
                        <a target="_blank" rel="noopener external noreferrer" href={text}>
                            <Tooltip
                                title="A website URL was not provided, and therefore this website URL was deducted from a Google search.">

                                {text}    &nbsp;
                                <Icon style={{color: "red"}} type="exclamation-circle"/>
                            </Tooltip>
                        </a>
                    )
                }
                return (
                    <a target="_blank" rel="noopener external noreferrer" href={text}>
                        {text}
                    </a>)
            }
        },
        {
            title: 'Company match',
            key: 'companyMatchResult',
            sorter: (a: ScrapeRunItemDetails, b: ScrapeRunItemDetails) => {
                if (a.companyMatch == null && b.companyMatch == null) {
                    return 0
                }

                if (a.companyMatch != null && b.companyMatch != null) {
                    return 1
                }

                if (b.companyMatch == null && a.companyMatch != null) {
                    return -1
                }

                return a.companyMatch.localeCompare(b.companyMatch);
            },
            filters: [
                {text: 'FULL_MATCH', value: 'FULL_MATCH'},
                {text: 'PARTIAL_MATCH', value: 'PARTIAL_MATCH'},
                {text: 'NO_MATCH', value: 'NO_MATCH'},
            ],
            onFilter: (value: string, record: ScrapeRunItemDetails) => (getCompanyResultFromRecord(record) != null && getCompanyResultFromRecord(record).includes(value)),
            render: (status: string, record: ScrapeRunItemDetails) => {
                if (status === "SKIPPED" || record.status === "CREATED" || record.status === "SKIPPED") {
                    return;
                }

                const result = getCompanyResultFromRecord(record)
                const tooltipText = (record: ScrapeRunItemDetails) => {
                    return (
                        <div>
                            City found: {record.cityFound.toString()} <br/>
                            Address found: {record.addressFound.toString()}<br/>
                            ZipCode found: {record.zipCodeFound.toString()}<br/>
                            Company name found: {record.companyNameFound.toString()}
                        </div>

                    )
                }

                const icon = (result: string) => {
                    if (result === 'NO_MATCH') {
                        return <Icon style={{color: "red"}} type="exclamation-circle"/>
                    }
                    return;
                }
                return (
                    <Tooltip style={{whiteSpace: "pre"}} title={tooltipText(record)}>
                        <span>{icon(result)} {result}</span>
                    </Tooltip>
                )

            }
        },
        {
            title: 'Status',
            filters: [
                {text: 'SKIPPED', value: 'SKIPPED'},
                {text: 'CREATED', value: 'CREATED'},
                {text: 'FAILED', value: 'FAILED'},
                {text: 'FINISHED', value: 'FINISHED'},
            ],
            dataIndex: 'status',
            sorter: (a: ScrapeRunItemDetails, b: ScrapeRunItemDetails) => {
                return (a.status.localeCompare(b.status))
            },
            onFilter: (value: string, record: ScrapeRunItemDetails) => (record.status != null && record.status.includes(value)),
        },
        {
            title: 'Timed out',
            key: 'timedOut',
            render: (text: string, record: ScrapeRunItemDetails) => {
                if (record.timedOut) {
                    return <Icon type="issues-close"/>
                }
            }
        },
        {
            title: 'Reason',
            key: 'reason',
            dataIndex: 'reason',
            sorter: (a: ScrapeRunItemDetails, b: ScrapeRunItemDetails) => {
                if (a.reason == null) {
                    return -1
                }
                if (b.reason == null) {
                    return 1
                }

                return (a.reason.localeCompare(b.reason))

            },
        },
        {
            title: 'Status',
            key: 'statusIcon',
            dataIndex: 'status',
            render: (status: string) => {

                if (props.scrapeRun.status === "FINISHED" && status === "CREATED") {
                    return (<Icon style={{color: 'black'}} type="close-outlined"/>)
                }

                if (status === "SKIPPED") {
                    return (<Icon style={{color: 'black'}} type="fast-forward"/>)
                }

                if (status !== "CREATED") {
                    return (<Icon style={{color: 'black'}} type="check"/>)
                }

                return (<Icon style={{color: 'green'}} type="loading"/>)


            },
            sorter: (a: ScrapeRunItemDetails, b: ScrapeRunItemDetails) => {
                return (a.status.localeCompare(b.status))
            },
        },
        {
            title: 'Screenshots',
            key: 'Screenshots',
            render: (text: string, record: ScrapeRunItemDetails) => {
                if (record.status === "FINISHED" ||
                    (record.status === "FAILED" && record.reason && record.reason.includes('Flash object on page'))) {
                    const url = `/screenshots/${props.scrapeRun.id}/${record.id}`;
                    return (
                        <a target="_blank" rel="noopener noreferrer" href={url}> Download</a>
                    )
                }
            }
        },
        {
            key: 'Quality check',
            render: (text: string, record: ScrapeRunItemDetails) => {
                if (record.status === 'Created') {
                    return;
                }

                return (
                    <Tooltip title="Quality check">
                        <ThumbsComponent scrapeRunId={props.scrapeRun.id} thumbScore={record.thumbScore}
                                         scrapeRunItemId={record.id} postThumbScore={props.postThumbScore}/>
                    </Tooltip>
                )
            }
        },
        {
            key: 'Feedback',
            render: (text: string, record: ScrapeRunItemDetails) => {
                if (record.status === 'Created') {
                    return;
                }

                return (
                    <Tooltip title="Feedback">
                        <Button shape="circle" type="dashed" icon="message" onClick={() => {
                            setModalVisible(true);
                            setSelectedScrapeRunItemId(record.id)
                        }}/>
                    </Tooltip>
                )
            }
        },
    ];


    const postFeedback = async (remarks: string) => {
        const api = new ScrapeApi();
        try {
            await api.postFeedback(props.scrapeRun.id, selectedScrapeRunItemId, remarks);
            hideModal();
            notification['success']({
                message: `Feedback given successfully`,
                description: `Thank your for providing us with feedback.`,
                duration: 3
            });
            return true;
        } catch (e) {
            hideModal();
            notification['error']({
                message: `Error submitting feedback`,
                description: e.message,
                duration: 3
            });
            return false
        }


    };

    const hideModal = () => {
        setModalVisible(false)
    };

    return (
        <div>
            <Table tableLayout="fixed" rowKey="id" defaultExpandAllRows={true} loading={props.isLoading}
                   columns={columns}
                   dataSource={props.scrapeRunItems}

                   pagination={{
                       showSizeChanger: true,
                       pageSizeOptions: ['10', '50', '75', '100', '250'],
                       defaultPageSize: 75
                   }}/>

            <WrappedFeedbackForm
                visible={modalVisible}
                onCancel={hideModal}
                onSubmit={postFeedback}
            />
            :
        </div>
    )
};


export default ScrapeItemDetailsTable;
