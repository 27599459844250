import React  from 'react';
import { Icon} from 'antd';
import { Result } from 'antd';
import {CrawlSteps} from "./Steps";

const ScrapeQueued = () => {
    return (
        <div>
            <CrawlSteps currentStep={2}/>
            <Result
                icon={<Icon type="clock-circle" style={{color: '#ffe600'}} />}
                title="Your job is currently queued!"
                subTitle="Please wait while we are spinning up some more workers to speed up the process"
            />
        </div>
    )
}

export default ScrapeQueued