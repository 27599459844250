import {ScrapeRunDetails} from "../components/UploadJobSpecificationScreen";


export type ErrorMessage = {
    code: number;
    message: string
}


export class ScrapeApi {
    getAllFeedback = async () => {
        let response = await fetch(`/api/v1/feedback`);
        if (!response.ok) {
            throw Error("Error fetching feedback")
        }
        return response.json()
    }
    getScrapeRunDetails = async (scrapeRunId: string) => {
        let response = await fetch(`/api/v1/scrape/${scrapeRunId}`);
        if (!response.ok) {
            throw Error("Error fetching scrape details")
        }
        return response.json()
    };
    getAllScrapeRuns = async () => {
        let response = await fetch(`/api/v1/scrape/`);
        if (!response.ok) {
            throw Error("Error fetching scrape details")
        }
        return response.json()
    };

    postThumbs = async (scrapeRunId: string, scrapeRunItemId: string, score: number) => {
        let scoreMutation = 'decrease'
        if(score > 0){
            scoreMutation = 'increase'
        }

        let response = await fetch(`/api/v1/scrape/${scrapeRunId}/items/${scrapeRunItemId}/thumbs/${scoreMutation}`,
            {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'}
            });


        const responseData = await response.json();
        if (response.status !== 201) {
            throw new Error(`Error adding thumbs: ${responseData.message}`)
        }

        return responseData
    };

    postFeedback = async (scrapeRunId: string, scrapeRunItemId: string, remarks: string) => {
        const body = {
            remarks: remarks
        };

        let response = await fetch(`/api/v1/scrape/${scrapeRunId}/items/${scrapeRunItemId}/feedback`,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {'Content-Type': 'application/json'}
            });

        const responseData = await response.json();
        if (response.status !== 201) {
            throw new Error(`Error adding feedback: ${responseData.message}`)
        }

        return responseData

    };
    cancelRun = async (scrapeRunId: string): Promise<ScrapeRunDetails> => {
        const payload = {
            status: "CANCELLED"
        };

        let response = await fetch(`/api/v1/scrape/${scrapeRunId}`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {'Content-Type': 'application/json'}
            });

        return await response.json()
    };

    forceFinishRun = async (scrapeRunId: string): Promise<ScrapeRunDetails> => {
        const payload = {
            status: "FORCE_FINISH"
        };

        let response = await fetch(`/api/v1/scrape/${scrapeRunId}`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {'Content-Type': 'application/json'}
            });

        return await response.json()
    };


    runWithoutKeywords = async (scrapeRunId: string) => {
        let response = await fetch(`/api/v1/scrape/${scrapeRunId}/screenshots`,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            });

        const formatted = await response.json();

        return await formatted
    }
}
