import React  from 'react';
import { Icon} from 'antd';
import { Result } from 'antd';

const ScrapeZipping = () => {
    return (
        <Result
            icon={<Icon type="file-zip" style={{color: '#ffe600'}} />}
            title="Putting everything together"
            subTitle="We are currently packaging your scrape run. "
        />                 
    )
    
}

export default ScrapeZipping