import React, {useEffect, useState} from "react";
import {ScrapeRunDetails, ScrapeRunItemDetails} from "./UploadJobSpecificationScreen";
import {ScrapeApi} from "../services/ScrapeApi";
import ErrorScreen from "./ErrorScreen";
import FeedbackOverviewTable from "./FeedbackOverviewTable";

export interface IFeedback {
    id: string
    remark: string
    scrapeRunItem: ScrapeRunItemDetails
    scrapeRun: ScrapeRunDetails
    createdAt: string
}

const FeedbackOverviewScreen: React.FC<{}> = (props) => {
    const [feedback, setFeedback] = useState(Array<IFeedback>());
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const API = new ScrapeApi();
        API.getAllFeedback().then(async result => {
            setFeedback(result);
            console.log(result)
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setError(true);
        })
    }, []);

    if(error){
        return (
            <ErrorScreen />
        )
    }
    return (
        <FeedbackOverviewTable key="id" isLoading={isLoading} feedback={feedback}/>

    );
};

export default FeedbackOverviewScreen