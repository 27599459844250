import React, {useEffect, useState} from "react";
import {ScrapeRunItemDetails} from "./UploadJobSpecificationScreen";
import {ScrapeApi} from "../services/ScrapeApi";
import ScrapeRunOverviewTable from "./ScrapeRunOverviewTable";
import ErrorScreen from "./ErrorScreen";
import {Button, Icon, Result} from "antd";

export interface IScrapeRun {
    id: string
    name: string
    inputFileLocation: string
    numberOfWebsitesProcessed: number
    totalNumberOfWebsites: number
    outputFileLocation: string
    createdAt: Date,
    finishedAt: Date,
    updatedAt: Date,
    status: string
    items: Array<ScrapeRunItemDetails>,

}

const LandingScreen: React.FC<{}> = (props) => {
    const [data, setData] = useState(Array<IScrapeRun>());
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const API = new ScrapeApi();
        API.getAllScrapeRuns().then(async result => {
            setData(result);
            setLoading(false);
        }).catch(err => {
            setError(true);
        })
    }, []);

    if (error) {
        return (
            <ErrorScreen/>
        )
    }
    return (
        <div>
            <Result
                icon={<Icon type="dashboard" theme="outlined"/>}
                title="Do you want to start a new scrape run?"
                subTitle="Depending on your decision, different filters are applied."
                extra={[
                    <Button href="/start/pre" type="primary" key="pre" icon="login">
                        Start a run before internet-review
                    </Button>,
                    <Button href="/start/post" type="default" key="post" icon="logout">
                        Start a run after internet-review
                    </Button>
                ]}
            />
            <ScrapeRunOverviewTable isLoading={isLoading} scrapeRuns={data}/>
        </div>

    );
};

export default LandingScreen