import {Modal, Form, Input} from 'antd';
import React from "react";
import {FormComponentProps} from "antd/es/form";

const {TextArea} = Input;

interface TFeedbackFormProps extends FormComponentProps {
    visible: boolean

    onCancel(): void;

    onSubmit(remarks: string): Promise<boolean>;

}


class FeedbackForm extends React.Component<TFeedbackFormProps> {
    handleSubmit = (e: any) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const success = this.props.onSubmit(values.remarks);
                if (success) {
                    this.props.form.resetFields()
                }
            }
        });
    };


    render() {
        const {getFieldDecorator} = this.props.form;

        let content = (
            <div>
                <p>Please provide us with feedback in case you ran into problems with this PDF.</p>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('remarks', {
                            rules: [{required: true, message: 'Please give your feedback!'}],
                        })(
                            <TextArea
                                placeholder="Please describe the problem as accurate as possible."
                                autoSize={{minRows: 5, maxRows: 10}}
                            />,
                        )}
                    </Form.Item>
                </Form>
            </div>);

        return (
            <Modal
                visible={this.props.visible}
                title="Feedback"
                okText="Submit"
                onCancel={this.props.onCancel}
                onOk={this.handleSubmit}
            >
                {content}
            </Modal>

        );
    };
}

const WrappedFeedbackForm = Form.create<TFeedbackFormProps>()(FeedbackForm);

export default WrappedFeedbackForm

