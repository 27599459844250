import {Steps, Icon} from 'antd';
import React from "react";
import '../App.less'
import {ScrapeRunDetails} from "./UploadJobSpecificationScreen";

const {Step} = Steps;

type TStepProps = {
    scrapeRun?: ScrapeRunDetails
    currentStep: number
}
export const CrawlSteps = (props: TStepProps) => {

    if (props.scrapeRun && !props.scrapeRun.withKeywords) {
        return (
            <Steps current={props.currentStep} style={{marginBottom: 24}}>
                <Step title="Upload BAT" description="Upload the BAT-file."/>
                <Step title="Upload keywords" description="No keywords uploaded (skipped)."
                          icon={<Icon type="fast-forward"/>}/>
                <Step title="Running" description="Processing the scrape run."/>
                <Step title="Finished" description="Download the results."/>
            </Steps>

        )

    }
    return (
        <Steps current={props.currentStep} style={{marginBottom: 24}}>
            <Step title="Upload BAT" description="Upload the BAT-file."/>
            <Step title="Upload keywords"/>

            <Step title="Running" description="Processing the scrape run."/>
            <Step title="Finished" description="Download the results."/>
        </Steps>
    )
}

