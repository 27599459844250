import {Table, Button} from "antd";
import React from "react";
import {IScrapeRun} from "./LandingScreen";
import Moment from 'moment';

type TScrapeRunOverviewTableProps = {
    scrapeRuns: Array<IScrapeRun>,
    isLoading: boolean
}

const ScrapeRunOverviewTable = (props: TScrapeRunOverviewTableProps) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Finished',
                    value: 'Finished',
                },
                {
                    text: 'Cancelled',
                    value: 'Cancelled',
                },
                {
                    text: 'Running',
                    value: 'Running',
                },
                {
                    text: 'Created',
                    value: 'Created',
                },

            ],
            sorter: (a: IScrapeRun, b: IScrapeRun) => a.status.localeCompare(b.status),
            onFilter: (value: string, record: IScrapeRun) => record.status.indexOf(value) === 0,
        },
        {
            title: 'Created ',
            dataIndex: 'createdAt',
            key: 'createdAt',
            defaultSortOrder: 'ascend' as 'ascend',

            render: (text: string, record: IScrapeRun) => {
                const createdAt = Moment(record.createdAt);

                return (createdAt.fromNow())
            },
            sorter: (a: IScrapeRun, b: IScrapeRun) => {
                return (Moment(b.createdAt).unix() - Moment(a.createdAt).unix())
            }
        },
        {
            title: 'Finished',
            dataIndex: 'finishedAt',
            key: 'finishedAt',
            render: (text: string, record: IScrapeRun) => {
                if (record.finishedAt == null) {
                    return ('')
                }

                const finishedAt = Moment(record.finishedAt);
                return (finishedAt.fromNow())
            },
            sorter: (a: IScrapeRun, b: IScrapeRun) => {
                return (Moment(b.finishedAt).unix() - Moment(a.finishedAt).unix())
            }
        },
        {
            title: 'Processed',
            dataIndex: 'numberOfWebsitesProcessed',
            key: 'numberOfWebsitesProcessed',
        },
        {
            title: 'Total',
            dataIndex: 'totalNumberOfWebsites',
            key: 'totalNumberOfWebsites',
        },
        {
            title: 'Details',
            render: (text: string, record: IScrapeRun) => {
                const url = `scrape/${record.id}`;
                return (
                    <Button href={url} rel="noopener noreferrer" shape="circle" type="dashed" icon="search"
                            onClick={() => {
                            }}/>
                )
            }
        }];

    const runs = props.scrapeRuns.filter((run: IScrapeRun) => {
        if (run.totalNumberOfWebsites === 0) {
            return false;
        }
        return true;
    });

    return (
        <div>


            <Table rowKey="id"
                   defaultExpandAllRows={true} columns={columns} dataSource={runs}
                   loading={props.isLoading}/>
        </div>
    );
};


export default ScrapeRunOverviewTable