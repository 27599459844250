import {Table, Button} from "antd";
import React from "react";
import Moment from 'moment';
import {IFeedback} from "./FeedbackOverviewScreen";

type TFeedbackOverviewTableProps = {
    feedback: Array<IFeedback>,
    isLoading: boolean;
}

const FeedbackOverviewTable = (props: TFeedbackOverviewTableProps) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark'
        },
        {
            title: 'Website',
            key: 'website',
            render: (text: string, record: IFeedback) => {
                return (
                    <a target="_blank" rel="noopener noreferrer"
                       href={record.scrapeRunItem.websiteUrl}>{record.scrapeRunItem.websiteUrl} </a>
                )
            }
        },
        {
            title: 'User',
            dataIndex: 'userIdentity',
            key: 'userIdentity'
        },
        {
            title: 'Created',
            key: "created",
            defaultSortOrder: 'ascend' as 'ascend',

            render: (text: string, record: IFeedback) => {
                const createdAt = Moment(record.createdAt);

                return (createdAt.fromNow())
            },
            sorter: (a: IFeedback, b: IFeedback) => {
                return (Moment(a.createdAt).unix() - Moment(b.createdAt).unix())
            }
        },
        {
            title: 'Scrape run',
            key: 'scrapeRun',
            defaultSortOrder: 'ascend' as 'ascend',

            render: (text: string, record: IFeedback) => {
                const url = `/scrape/${record.scrapeRun.id}`;
                return (
                    <Button target="_blank" href={url} rel="noopener noreferrer" shape="circle" type="dashed"
                            icon="search"
                            onClick={() => {
                            }}/>
                )
            }
        },
        {
            title: 'Screenshots',
            key: 'screenshot',
            render: (text: string, record: IFeedback) => {

                const url = `/screenshots/${record.scrapeRun.id}/${record.scrapeRunItem.id}`;
                return (
                    <a target="_blank" rel="noopener noreferrer" href={url}> Download</a>
                )
            }
        }

    ];


    return (
        <div>
            <Table rowKey="id"
                   defaultExpandAllRows={true} columns={columns} dataSource={props.feedback}
                   loading={props.isLoading}
                   pagination={{
                       showSizeChanger: true,
                       pageSizeOptions: ['10', '50', '100', '250', '500'],
                       defaultPageSize: 1000
                   }}

            />
        </div>
    );
};

export default FeedbackOverviewTable