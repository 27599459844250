import {Upload, Icon, notification, Button, Result} from 'antd';
import React, {useState} from 'react'
import {Spin} from 'antd';
import {UploadProps} from 'antd/lib/upload/Upload';
import {useHistory} from "react-router-dom";
import {CrawlSteps} from "./Steps";
import {ScrapeApi} from "../services/ScrapeApi";
import {ScrapeRunDetails} from "./UploadJobSpecificationScreen";

const {Dragger} = Upload;


const UploadKeywordScreen: React.FC<{}> = (props) => {
    // @ts-ignore
    const {match} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [, setIsUploaded] = useState(false);
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    let history = useHistory();

    const API = new ScrapeApi();
    const onSkipButtonClick = async () => {
        setButtonIsLoading(true);
        const response = await API.runWithoutKeywords(match.params.id);
        if (response && response.code === 400) {
            notification['error']({
                message: `Failed to start scrape run.`,
                description: `${response.message}`,
                duration: 0,
                style: {
                    width: 400,
                }
            })
            setButtonIsLoading(false);
            const path = `/`;
            history.push(path)

            return
        }
        const path = `/scrape/${match.params.id}`;
        history.push(path)
    };


    const settings: UploadProps = {
        name: 'keywordSpecification',
        action: `/api/v1/scrape/${match.params.id}/keywords`,
        showUploadList: false,
        onChange(info: any) {
            const {status} = info.file;

            if (status === 'uploading') {
                setIsLoading(true);
            }
            if (status === 'done') {
                const scrapeRunDetails: ScrapeRunDetails = info.file.response;
                setIsLoading(false);

                notification['success']({
                    message: `${info.file.name} uploaded successfully.`,
                    description: `Please wait for your scrape run to get started.`
                });

                const path = `/scrape/${scrapeRunDetails.id}`;
                history.push(path);

                setIsUploaded(true);

            } else if (status === 'error') {
                console.log("status", info)
                setIsLoading(false);
                if (info.file.response.message == null) {
                    notification['error']({
                        message: `${info.file.name} upload failed.`,
                        description: `${info.file.response}`,
                        duration: 0,
                        style: {
                            width: 400,
                        }
                    })
                    return
                }

                const messages = info.file.response.message.split(',');
                for (const message of messages) {
                    notification['error']({
                        message: `${info.file.name} upload failed.`,
                        description: `${message}`,
                        duration: 0,
                        style: {
                            width: 400,
                        }
                    })
                }
            }
        }
    };

    const antIcon = <Icon type="loading" style={{fontSize: 24, color: '#808080'}} spin/>;

    return (
        <div>
            <Spin size="large" delay={200} spinning={isLoading} indicator={antIcon}>
                <CrawlSteps currentStep={1}/>
                <br/>
                <Result
                    icon={<Icon type="dashboard" theme="outlined"/>}
                    title="Screenshots only?."
                    extra={
                        <Button loading={buttonIsLoading} onClick={onSkipButtonClick}> No keywords, just
                            screenshots!</Button>
                    }
                />
                <Dragger {...settings}>
                    <p className="ant-upload-drag-icon">
                        <Icon style={{color: '#808080'}} type="inbox"/>
                    </p>
                    <p className="ant-upload-text">Click or drag Keyword file to this area</p>
                    <p className="ant-upload-hint">
                        The keywords are automatically translated into multiple languages. Short (less than 4
                        characters) keywords are not translated. <br/>
                        Keywords containing wildcards are auto completed and their matched keywords in English are
                        translated and added to the search. <br/>
                        Some excluding keywords (cooperative, parent, subsidiary) are automatically added to the search.
                    </p>

                </Dragger>
            </Spin>
        </div>
    )
};

export default UploadKeywordScreen